 .App-header {
	min-height: 100vh;
	flex-direction: column;
	align-items: left;
	justify-content: center;
	font-size: calc(8px + 2vmin);
}
.rotate-north {
	bottom: 100px;
	right: .5em;
}
.ol-touch .rotate-north {
	top: 80px;
}
.studies-button {
	background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(196,29,253,1) 50%, rgba(252,69,105,1) 100%);
}

.flex-col {
	display: flex;
	flex-direction: column;
	align-items: center
}

.flex-row {
	display: flex;
	flex-direction: row;
	align-items: center;
}
